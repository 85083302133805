import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer.component';
import {TranslateModule} from "@ngx-translate/core";
import {DonateButtonComponent} from "../donate-button/donate-button.component";


@NgModule({
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        DonateButtonComponent,
    ]
})
export class FooterModule { }
