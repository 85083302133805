import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from "@angular/router"
import {TranslateAppService} from "../../services/app/translate/translate.app.service";
import {LanguageCodeEnum, MapOfLanguageNameAndCode} from "../../enums/language-code.enum";
import {NavPageLinkEnum} from "../../enums/nav-page-link.enum";

@Component({
  selector: 'footer[car4ukraine]',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
  host: {
    'class': 'footer bg-dark'
  }
})
export class FooterComponent implements OnInit {
[x: string]: any;

  public readonly mapOfLanguageNameAndCode = MapOfLanguageNameAndCode;
  public label: string = "modules.logo.label";
  public labelSize: string = "modules.logo.label_size";
  public socialMediaLinks: {
    'title': string,
    'href': string,
    'bi_icon':string
  }[] = [
    {
      'title': 'Car for Ukraine Facebook',
      'href': 'https://www.facebook.com/car4ukraine',
      'bi_icon': 'bi-facebook'
    },
    {
      'title': 'Car for Ukraine Instagram',
      'href': 'https://www.instagram.com/car4ukraine.com_official/',
      'bi_icon': 'bi-instagram'
    },
    {
      'title': 'Car for Ukraine LinkedIn',
      'href': 'https://www.linkedin.com/company/car4ukraine',
      'bi_icon': 'bi-linkedin'
    },
    {
      'title': 'Car for Ukraine Twitter',
      'href': 'https://twitter.com/carforukraine',
      'bi_icon': 'bi-twitter'
    },
  ]
  mobile = window.innerWidth < 992

  constructor(
    private readonly translateAppService: TranslateAppService,
    private readonly router: Router 
  ) {
  }

  public get languageCodeList(): LanguageCodeEnum[] {
    return this.translateAppService.languageCodeList as LanguageCodeEnum[];
  }

  public get currentLanguageCode(): string {
    return this.translateAppService.currentLanguageCode;
  }

  public getLanguageLinkToCurrentPage(languageCode:string): string {
    const lastPartOfCurretnUrl = this.router.url.split('/').pop() 
    if (Object.values(NavPageLinkEnum).includes(lastPartOfCurretnUrl as unknown as NavPageLinkEnum)){
      return `${languageCode}/${lastPartOfCurretnUrl}`
    }
    if (languageCode===LanguageCodeEnum.en && lastPartOfCurretnUrl){
      return ''
    }
    return languageCode
  }
  

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mobile = window.innerWidth < 992;
  }

}
