import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from 'rxjs';
import {CampaignsApiService} from "../../api/campaigns/campaigns.api.service";
import {Reactive} from "../../../../app/reactive";

/**
 *  0: "status",
 *  1: "title",
 *  2: "description",
 *  3: "photo_url",
 *  4: "progress_bar_html",
 *  5: "url",
 *  6: "comment_html"
 *  7: donate_button
 */

class MainCampaign {
  status: string = '';
  title: string = '';
  description: string = '';
  photoUrl: string = '';
  progressBarHtml: string = '';
  url: string = '';
  commentHtml: string = '';
  donateButton: string = '';

  public static createFromArrayString(array: string[]): MainCampaign {
    const instance = new MainCampaign();
    instance.status = array[0];
    instance.title = array[1];
    instance.description = array[2];
    instance.photoUrl = array[3];
    instance.progressBarHtml = array[4];
    instance.url = array[5];
    instance.commentHtml = array[6];
    instance.donateButton = array[7];
    return instance;
  }

}

@Injectable({
  providedIn: 'root'
})
export class MainCampaignAppService extends Reactive {

  #data: MainCampaign | null = null;

  #data$: BehaviorSubject<MainCampaign | null> = new BehaviorSubject<MainCampaign | null>(null);

  constructor(
    private readonly campaignsApiService: CampaignsApiService,
  ) {
    super();
    this.prepareData();
  }

  public get data$(): Observable<MainCampaign | null> {
    return this.#data$.asObservable();
  }

  public prepareData(): void {
    this.campaignsApiService.getCampaignMainPage$().pipe(this.takeUntil()).subscribe((result) => {
      this.#data = MainCampaign.createFromArrayString(result.flat());
      this.#data$.next(this.#data);
    });
  }

}
