import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CartApiService {

  public readonly endpointOrder: string = `${environment.hostContacts}?spreadsheetId=1rUiJVF0DvbGNkrc2OpeWGEObs8k_c9sCF5xXVr2rYnU&range=orders`;
  public readonly endpointProductPerOrder: string = `${environment.hostContacts}?spreadsheetId=1rUiJVF0DvbGNkrc2OpeWGEObs8k_c9sCF5xXVr2rYnU&range=product_per_order`;

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  /**
   *
   * @param body
   */
  public postOrder(body: string): Observable<Object> {
    return this.httpClient.post(this.endpointOrder, body);
  }

  /**
   *
   * @param body
   */
  public postProductPerOrder(body: string): Observable<Object> {
    return this.httpClient.post(this.endpointProductPerOrder, body);
  }

}
