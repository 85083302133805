import {Component, inject} from '@angular/core';
import {MainCampaignAppService} from "../../services/app/campaigns/main-campaign.app.service";
import {TranslateModule} from "@ngx-translate/core";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-donate-button',
  templateUrl: './donate-button.component.html',
  styleUrls: ['./donate-button.component.scss'],
  imports: [
    TranslateModule,
    NgIf,
    AsyncPipe
  ],
  standalone: true
})
export class DonateButtonComponent {

  public label: string = "modules.buttons.donate";

  private readonly mainCampaignAppService = inject(MainCampaignAppService);

  public get mainCampaign$() {
    return this.mainCampaignAppService.data$;
  }

}
