import {inject, Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignsApiService {

  public readonly endpoint: {
    base: string;
    campaign_main_page: string;
    campaigns_page: string
  } = {
    base: `${environment.host}/special-projects`,
    campaign_main_page: '/campaign_main_page',
    campaigns_page: '/campaigns_page'
  };

  private readonly httpClient = inject(HttpClient);

  /**
   *  0: "status",
   *  1: "title",
   *  2: "description",
   *  3: "photo_url",
   *  4: "progress_bar_html",
   *  5: "url"
   */
  public getCampaignsPage$(): Observable<string[][]> {
    return this.httpClient.get<string[][]>(`${this.endpoint.base}${this.endpoint.campaigns_page}`);
  }

  /**
   *  0: "status",
   *  1: "title",
   *  2: "description",
   *  3: "photo_url",
   *  4: "progress_bar_html",
   *  5: "url",
   *  6: "comment_html"
   *  7: donate_button
   */
  public getCampaignMainPage$(): Observable<string[][]> {
    return this.httpClient.get<string[][]>(`${this.endpoint.base}${this.endpoint.campaign_main_page}`);
  }

}
