import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {v4} from "uuid";
import {CartApiService} from "../../api/cart/cart.api.service";

export interface ItemInterface {
  id: string;
  item: any;
  count: number;
};

@Injectable({
  providedIn: 'root'
})
export class CartService {

  /**
   *
   *     {
   *       "id": "{\"images\":[],\"name\":\"\\\"after war experiance\\\" Ukrainian tour\",\"price\":{\"dollar\":430,\"euro\":390}}",
   *       "item": {
   *         "images": [],
   *         "name": "\"after war experiance\" Ukrainian tour",
   *         "price": {
   *           "dollar": 430,
   *           "euro": 390
   *         }
   *       },
   *       "count": 3
   *     },
   *     {
   *       "id": "{\"images\":[],\"name\":\"\\\"Customize design\\\"\",\"price\":{\"dollar\":8500,\"euro\":7900}}",
   *       "item": {
   *         "images": [],
   *         "name": "\"Customize design\"",
   *         "price": {
   *           "dollar": 8500,
   *           "euro": 7900
   *         }
   *       },
   *       "count": 2
   *     },
   *     {
   *       "id": "{\"needShippingInformation\":true,\"images\":[],\"name\":\"\\\"Stand with ukraine\\\" Black t-shirt [Pre-order]\",\"price\":{\"dollar\":55,\"euro\":49}}",
   *       "item": {
   *         "needShippingInformation": true,
   *         "images": [],
   *         "name": "\"Stand with ukraine\" Black t-shirt [Pre-order]",
   *         "price": {
   *           "dollar": 55,
   *           "euro": 49
   *         }
   *       },
   *       "count": 5
   *     }
   * @private
   */
  private readonly items: ItemInterface[] = [];
  public readonly state$: Subject<ItemInterface[]> = new Subject<ItemInterface[]>();

  constructor(
    private readonly cartApiService: CartApiService,
  ) {
  }

  public get itemList(): ItemInterface[] {

    return this.items;

  }

  /**
   *
   * @param item
   */
  public removeItem(item: any): void {

    const {images, description, ...itemWithOutItem} = item;
    const newId: string = JSON.stringify(itemWithOutItem);
    const index: number = this.items.findIndex(({id}) => id === newId);
    this.items.splice(index, 1);
    this.updateSate();

  }

  /**
   *
   * @param item
   * @param count
   */
  public addItem(item: any, count: number = 1): void {

    const {images, description, ...itemWithOutItem} = item;
    const newId: string = JSON.stringify(itemWithOutItem);
    const foundItem: ItemInterface | undefined = this.items.find(({id}) => id === newId);

    if (foundItem) {
      foundItem.count += count;
    } else {

      this.items.push({
        id: newId,
        item: item,
        count: count
      });

    }

    this.updateSate();

  }

  public updateSate(): void {

    this.state$.next(this.items);

  }

  public get countOfItems(): number {

    return this.items.map(({count}) => count).reduce((previousValue: number, currentValue: number) => {
      return previousValue + currentValue;
    }, 0);

  }

  public get json(): string {
    return JSON.stringify(this.jsonObject);
  }

  public get totalInDollar(): number {
    return this.jsonObject.products.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.totalInDollar;
    }, 0);
  }

  public get totalInEuro(): number {
    return this.jsonObject.products.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.totalInEuro;
    }, 0);
  }

  public get needShippingInformation(): boolean {
    return this.jsonObject.products.some((product) => product?.needShippingInformation);
  }

  public get jsonObject(): {
    id: string,
    products: any[]
  } {

    const orderId: string = v4();

    return {
      id: orderId,
      products: this.items.map((item) => {
        return {
          id: item.id,
          orderId: orderId,
          count: item.count,
          name: item.item.name,
          needShippingInformation: item.item?.needShippingInformation ?? false,
          dollar: item.item.price.dollar,
          euro: item.item.price.euro,
          totalInDollar: item.item.price.dollar * item.count,
          totalInEuro: item.item.price.euro * item.count,
          size: item?.item?.size ?? '-',
        }
      })
    };

  }

  public send({
         firstname,
         lastname,
         email,
         country,
         company,
         address,
         apartment,
         city,
         postalCode,
         phone,
       }: {
    firstname: any
    lastname: any
    email: any
    country: any
    company: any
    address: any
    apartment: any
    city: any
    postalCode: any
    phone: any
  }): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      const order = this.jsonObject;
      const totalCount: number = this.countOfItems;
      const now: Date = new Date();
      this.cartApiService.postOrder(JSON.stringify({
        id: order.id,
        totalInDollar: this.totalInDollar,
        totalInEuro: this.totalInEuro,
        firstname: firstname ?? '-', // from form
        lastname: lastname ?? '-', // from form
        email: email ?? '-', // from form
        country: country ?? '-', // from form
        company: company ?? '-', // from form
        address: address ?? '-', // from form
        apartment: apartment ?? '-', // from form
        city: city ?? '-', // from form
        postalCode: postalCode ?? '-', // from form
        phone: phone ?? '-', // from form
        totalCount,
        paid: 0,
        shipped: 0,
        createdAt: (new Date()).toISOString(),
        date: `${now.getFullYear()}-${(now.getMonth()+1).toFixed().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`,
      })).subscribe(() => {
        Promise.all(
          order?.products?.map((product) => {
            return this.wrapToPromise(this.cartApiService.postProductPerOrder(JSON.stringify(product)));
          })
        ).then((result) => {
          resolve(result);
        }).catch(() => {
          reject();
        });
      }, () => {
        reject();
      });

    });

  }

  private wrapToPromise(objectObservable: Observable<Object>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      objectObservable.subscribe({
        next: (res) => {
          resolve();
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {

        }
      })
    });
  }

}
