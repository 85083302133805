import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit,} from '@angular/core';
import {TranslateAppService} from "../../services/app/translate/translate.app.service";
import {DefaultLangChangeEvent, TranslateService} from "@ngx-translate/core";
import {CartService} from "../../services/app/cart/cart.service";
import {Collapse} from 'bootstrap';
import {LanguageCodeEnum, MapOfLanguageNameAndCode} from "../../enums/language-code.enum";
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {Reactive} from "../../../app/reactive";
import {Observable, startWith} from "rxjs";

@Component({
  selector: 'header[car4ukraine]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    // 'class': 'fixed-top',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends Reactive implements OnInit {

  public readonly tags$: Observable<{ [key: string]: { name: string; }; }> = this.translateService.get("modules.header.tags").pipe(
    this.takeUntil(),
    startWith({}),
  );
  public urls: string[] = ['', 'about-us', 'faq', 'contact', 'donate-car', 'campaigns'];

  lang: any = this.translateAppService.getLanguageCode();

  public label: string = "modules.logo.label";
  public label_size: string = "modules.logo.label_size";
  private prevScrollpos = window.pageYOffset;

  @Input()
  public readonly mapOfLanguageNameAndCode: typeof MapOfLanguageNameAndCode = MapOfLanguageNameAndCode;

  public mobile = window.innerWidth < 992;

  constructor(
    private meta: Meta,
    private title: Title,
    private readonly translateService: TranslateService,
    private readonly translateAppService: TranslateAppService,
    private readonly cartService: CartService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
  ) {
    super();
  }

  public hideNavbarOnScroll() {
    var navbar = document.getElementById('navbarSupportedContent');
    if (navbar) {
      var collapse = new Collapse(navbar, {
        toggle: false
      });
      collapse.hide();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mobile = window.innerWidth < 992;
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: Event) {

    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos <= 0) {

      const menu = document.getElementById("header-menu");
      if (menu) {
        menu.style.top = "0";
        menu.style.position = 'absolute';
      }
    } else {
      const menu = document.getElementById("header-menu");
      if (!menu) {
        this.prevScrollpos = currentScrollPos;
        return
      }
      menu.style.position = 'fixed';

      if (this.prevScrollpos > currentScrollPos) {
        menu.style.top = "0";

      } else {
        this.hideNavbarOnScroll()
        menu.style.top = "-54px";
      }

      this.prevScrollpos = currentScrollPos;
    }

  }

  public get countOfProductInCart(): number {
    return this.cartService.countOfItems ?? 0;
  }

  public ngOnInit(): void {
    this.initSubscribes();
    this.cartService.state$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });

    this.translateAppService.langUpdated.subscribe(
      (lang) => {
        this.lang = this.translateAppService.getLanguageCode();
      }
    );
  }

  public get currentLanguageCode(): string {
    return this.translateAppService.currentLanguageCode;
  }

  public trackByFn(index: number, item: {
    name: string;
    url: string;
  }): number {
    return index;
  }

  private initSubscribes(): void {
    this.initSubscribeOfChangeDefaultLanguage();
  }

  private initSubscribeOfChangeDefaultLanguage() {
    // In subscribe call back fn you can use argument - event: DefaultLangChangeEvent
    this.translateAppService.onLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  public get languageCodeList(): LanguageCodeEnum[] {
    return this.translateAppService.languageCodeList as LanguageCodeEnum[];
  }

  public changeLanguage(languageCode: LanguageCodeEnum): void {
    this.translateAppService.setLanguageCode(languageCode);

    const splitCurrentURL = this.router.url.split("/");
    const routes = ['about-us', 'faq', 'contact', 'special-projects', 'thankyou', 'donate', 'donate-car', 'campaigns'];
    const currentRoute = splitCurrentURL[splitCurrentURL.length - 1];
    const navigationArray = languageCode === "en" ? [currentRoute] : [languageCode, currentRoute]

    if (splitCurrentURL.length === 3) {
      this.router.navigate(navigationArray).then()
    } else if (splitCurrentURL.length === 2 && routes.includes(currentRoute)) {
      this.router.navigate([languageCode, currentRoute])
    } else {
      if (languageCode == "en") {
        this.router.navigate([""]).then()
      } else {
        this.router.navigate([languageCode]).then()
      }
    }

    this.translateAppService.changeLanguage(languageCode);
  }

  public setMetaData(url: string) {
    let meta_title = "pages.home.meta_title";
    let meta_description = "pages.home.meta_title";

    if (url === '') {
      meta_title = "pages.home.meta_title";
      meta_description = "pages.home.meta_description";
    }
    if (url === 'about-us') {
      meta_title = "pages.about_us.meta_title";
      meta_description = "pages.about_us.meta_description";
    }
    if (url === 'faq') {
      meta_title = "pages.faq.meta_title";
      meta_description = "pages.faq.meta_description";
    }
    if (url === 'contact') {
      meta_title = "pages.contact_us.meta_title";
      meta_description = "pages.contact_us.meta_description";
    }
    if (url === 'special-projects') {
      meta_title = "pages.special_projects.meta_title";
      meta_description = "pages.special_projects.meta_description";
    }
    if (url === 'campaigns') {
      meta_title = "pages.campaigns.meta_title";
      meta_description = "pages.campaigns.meta_description";
    }

    this.translateService.get(meta_title).subscribe((title: string) => {
      this.title.setTitle(title);
    })

    this.translateService.get(meta_description).subscribe((description: string) => {
      this.meta.updateTag({name: 'description', content: description});
    });
  }

}
