import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateAppService } from "../common/services/app/translate/translate.app.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LanguageCodeEnum } from "../common/enums/language-code.enum";
import { LangChangeEvent } from "@ngx-translate/core";

@Component({
  selector: 'app-language-wrapper',
  encapsulation: ViewEncapsulation.None,
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppLanguageWrapper implements OnInit {

  constructor(
    private readonly translateAppService: TranslateAppService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  public ngOnInit(): void {
    this.translateAppService.setLanguageCodeList();
    this.initSubscribes();
  }

  private initSubscribes(): void {
    this.initSubscribeOfLanguageCodeInUrl();
    this.initSubscribeOfChangeLanguage();
  }

  private initSubscribeOfLanguageCodeInUrl(): void {
    const languagePathName: string = 'languageCode';
    this.activatedRoute.params.subscribe((params: Params) => {
      this.translateAppService.changeLanguage(this.getLanguageCode(params[languagePathName]));
    });
  }


  private initSubscribeOfChangeLanguage() {
    this.translateAppService.onLangChange.subscribe((language: LangChangeEvent) => {
      if (!this.router.url.match(this.translateAppService.regExpForPath)) {
        this.router.navigateByUrl(`/${language.lang}${this.router.url}`);
      }

    });
  }

  private getLanguageCode(languageCode: string): LanguageCodeEnum {

    if (!(languageCode && languageCode in LanguageCodeEnum)) {
      const browserLang: string | undefined = this.translateAppService.browserLang;
      if (browserLang) {
        languageCode = browserLang.match(this.translateAppService.regExp) ? browserLang : LanguageCodeEnum.en;
      } else {
        languageCode = LanguageCodeEnum.en;
      }
    }

    return languageCode as LanguageCodeEnum;

  }

}

// Test Pull Request #1