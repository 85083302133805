import {EventEmitter, Injectable} from "@angular/core";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {LanguageCodeEnum} from "../../../enums/language-code.enum";
import {TypeGuard} from '@p4ck493/ts-type-guard';
import {is} from '@p4ck493/ts-is';
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class TranslateAppService {
  langUpdated: EventEmitter<string> = new EventEmitter();
  lang: string = 'en';

  constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  public get currentLanguageCode(): string {
    return this.translateService.currentLang;
  }

  public get languageCodeList(): string[] {
    return this.translateService.getLangs();
  }

  public get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  public get browserLang(): string | undefined {
    return this.translateService.getBrowserLang();
  }

  /**
   *
   * @param forPath
   * @private
   */
  public static regExp(forPath: boolean = false): RegExp {
    if (forPath) {
      return new RegExp(`\/` + Object.values(LanguageCodeEnum).join('|\/'));
    }
    return new RegExp(Object.values(LanguageCodeEnum).join('|'));
  }

  public get regExp(): RegExp {
    return TranslateAppService.regExp();
  }

  public get regExpForPath(): RegExp {
    return TranslateAppService.regExp(true);
  }

  @TypeGuard([is.not.null.or.undefined])
  public changeLanguage(languageCode: LanguageCodeEnum): void {
    this.translateService.use(languageCode);
  }

  @TypeGuard([is.not.null.or.undefined])
  public setLanguageCodeList(languageCodeList: string[] = Object.values(LanguageCodeEnum)): void {
    this.translateService.addLangs(languageCodeList);
  }

  public setLanguageCode(languageCode: string): void {
    this.lang = languageCode;
    this.langUpdated.emit(this.lang);
  }

  getLanguageCode(): string {
    return this.lang;
  }

}
