import {AfterViewInit, Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateAppService} from "../common/services/app/translate/translate.app.service";
import {ActivatedRoute, Params} from "@angular/router";
import {LanguageCodeEnum} from "../common/enums/language-code.enum";
import {is} from '@p4ck493/ts-is';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from "@angular/common";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'd-flex flex-column h-100'
  }
})
export class AppComponent implements OnInit, AfterViewInit {

  private readonly document = inject(DOCUMENT)

  constructor(
    private readonly translateAppService: TranslateAppService,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  public ngOnInit(): void {
    this.translateAppService.setLanguageCodeList();
    this.initSubscribes();
    // Sign
    if (is.object(window)) {
      const convertToString = (arr: any[]) => arr.map(i => i > 0 ? i.toString(36) : i).join('');
      Reflect.defineProperty(window, convertToString([10, 30, 29, 17, 24, 27]), {
        value: convertToString([18, 31, 10, 23, ' ', 20, 10, 27, 11, 10, 28, 17, 14, 31, 28, 20, 34, 18])
      });
    }

    document.addEventListener("DOMContentLoaded", () => {
      const hideMenuListener = document.getElementById("hideMenuListener");
      if (hideMenuListener) {
      }
    });
  }

  private initSubscribes(): void {
    this.initSubscribeOfLanguageCodeInUrl();
  }

  private initSubscribeOfLanguageCodeInUrl(): void {
    const languagePathName: string = 'languageCode';
    this.activatedRoute.params.subscribe((params: Params) => {
      this.translateAppService.changeLanguage(this.getLanguageCode(params[languagePathName]));
    });
  }


  private getLanguageCode(languageCode: string): LanguageCodeEnum {

    if (!(languageCode && languageCode in LanguageCodeEnum)) {
      const browserLang: string | undefined = this.translateAppService.browserLang;
      if (browserLang) {
        languageCode = browserLang.match(this.translateAppService.regExp) ? browserLang : LanguageCodeEnum.en;
      } else {
        languageCode = LanguageCodeEnum.en;
      }
    }

    return languageCode as LanguageCodeEnum;

  }

  public ngAfterViewInit() {
    this.tryToHideScullyAppRoot();
  }
  public tryToHideScullyAppRoot() {
    const scullyAppRoot = this.document.getElementsByTagName('app-root-scully')[0] as HTMLElement;
    if (scullyAppRoot) {
      scullyAppRoot.style.cssText = 'display:none !important';
    }
  }
}
