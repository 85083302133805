<header
  id="header-menu" class="navbar navbar-expand-lg navbar-dark bg-dark">

  <div class="container">
    <div class="tw-flex">
      <a class="navbar-brand !tw-m-0 !tw-p-1 !tw-pr-4" href="/">
        <img src="/assets/images/logo.png" alt="Car for Ukraine - Logo">
      </a>
      <div class="logo-text tw-text-nowrap tw-whitespace-nowrap">
        <h2 class="mb-0" [style.font-size]="label_size | translate" [innerText]="label | translate"></h2>
        <h2 class="mb-0">Car for Ukraine</h2>
      </div>
    </div>
    <button
      id="navbarTogglerButton"
      class="navbar-toggler collapsed"
      type="button"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent">
      <span class="toggler-icon top-bar"></span>
      <span class="toggler-icon middle-bar"></span>
      <span class="toggler-icon bottom-bar"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent">
      <ul class="navbar-nav navbar-hide-listener m-auto mb-2 mb-lg-0" *ngIf="tags$ | async as tags">
          <ng-container *ngFor="let tag of (tags | keyvalue);let index = index;">
            <li class="nav-item">
              <a
                class="nav-link text-uppercase"
                [routerLinkActive]="'active'"
                [routerLink]="urls[index] !== '' && lang && lang != 'en'? [lang, urls[index]] :
                urls[index] == '' && lang && lang != 'en' ? [lang] : urls[index]"
                (click)="setMetaData(urls[index])"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                [routerLinkActiveOptions]="{ exact: true }"
                [innerHTML]="tag?.value?.name ?? ''">
              </a>
            </li>
        </ng-container>
      </ul>
      <fieldset *ngIf="mobile" class="p-3">
        <legend class="text-white">Languages:</legend>
        <ul>
          <ng-container *ngFor="let languageCode of languageCodeList; let i = index">
            <li class="text-white list-unstyled" (click)="changeLanguage(languageCode)">
              <a
                [class.active]="languageCode === currentLanguageCode"
                class="dropdown-item"
                href="javascript:void(0)"
                [title]="mapOfLanguageNameAndCode[languageCode]"
                (click)="setMetaData(urls[i])">
                {{ mapOfLanguageNameAndCode[languageCode] }}
              </a>
            </li>
          </ng-container>
        </ul>
      </fieldset>
    </div>

    <app-translate-dropdown *ngIf="!mobile"></app-translate-dropdown>

    <div class="d-flex d-none d-lg-block">
      <app-donate-button></app-donate-button>
    </div>

  </div>
</header>
