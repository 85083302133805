export enum LanguageEnum {
  English = 'English',
  Danish = 'Dansk',
  German = 'Deutsch',
  Spain = 'Español',
  Finnish = 'Suomalainen',
  French = 'Français',
  Norwegian = 'Norsk',
  Dutch = 'Nederlandse',
  Polish = 'Polski',
  Swedish = 'Svenska',
}

export enum LanguageCodeEnum {
  en = 'en',
  da = 'da',
  de = 'de',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  nb = 'nb',
  nl = 'nl',
  pl = 'pl',
  sv = 'sv',
}

export const MapOfLanguageNameAndCode: Record<LanguageCodeEnum, LanguageEnum> = {
  [LanguageCodeEnum.en]: LanguageEnum.English,
  [LanguageCodeEnum.da]: LanguageEnum.Danish,
  [LanguageCodeEnum.de]: LanguageEnum.German,
  [LanguageCodeEnum.es]: LanguageEnum.Spain,
  [LanguageCodeEnum.fi]: LanguageEnum.Finnish,
  [LanguageCodeEnum.fr]: LanguageEnum.French,
  [LanguageCodeEnum.nb]: LanguageEnum.Norwegian,
  [LanguageCodeEnum.nl]: LanguageEnum.Dutch,
  [LanguageCodeEnum.pl]: LanguageEnum.Polish,
  [LanguageCodeEnum.sv]: LanguageEnum.Swedish,
}
