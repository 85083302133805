import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/home-page/home-page.module').then(m => m.HomePageModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../pages/faq-page/faq-page.module').then(m => m.FaqPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('../pages/about-us-page/about-us-page.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('../pages/contact-page/contact-page.module').then(m => m.ContactPageModule)
  },
  {
    path: 'donate',
    loadChildren: () => import('../pages/donate-page/donate-page.module').then(m => m.DonatePageModule)
  },
  {
    path: 'donate-car',
    loadChildren: () => import('../pages/donate-car-page/donate-car-page.module').then(m => m.DonateCarPageModule)
  },
  {
    path: 'special-projects',
    loadChildren: () => import('../pages/special-projects-page/special-projects-page.module').then(m => m.SpecialProjectsPageModule)
  },
  {
    path: 'campaigns',
    loadChildren: () => import('../pages/campaigns-page/campaigns-page.module').then(m => m.CampaignsPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('../pages/thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
  },
  {
    path: ':lang',
    loadChildren: () => import('../pages/home-page/home-page.module').then(m => m.HomePageModule),
  },

  {
    path: ':lang/faq',
    loadChildren: () => import('../pages/faq-page/faq-page.module').then(m => m.FaqPageModule)
  },
  {
    path: ':lang/about-us',
    loadChildren: () => import('../pages/about-us-page/about-us-page.module').then(m => m.AboutUsPageModule)
  },
  {
    path: ':lang/contact',
    loadChildren: () => import('../pages/contact-page/contact-page.module').then(m => m.ContactPageModule)
  },
  {
    path: ':lang/donate',
    loadChildren: () => import('../pages/donate-page/donate-page.module').then(m => m.DonatePageModule)
  },
  {
    path: ':lang/donate-car',
    loadChildren: () => import('../pages/donate-car-page/donate-car-page.module').then(m => m.DonateCarPageModule)
  },
  {
    path: ':lang/special-projects',
    loadChildren: () => import('../pages/special-projects-page/special-projects-page.module').then(m => m.SpecialProjectsPageModule)
  },
  {
    path: ':lang/campaigns',
    loadChildren: () => import('../pages/campaigns-page/campaigns-page.module').then(m => m.CampaignsPageModule)
  },
  {
    path: ':lang/thankyou',
    loadChildren: () => import('../pages/thankyou-page/thankyou-page.module').then(m => m.ThankyouPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot([...routes, {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }], {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
