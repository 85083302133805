<!-- <button class="btn btn-danger btn-lg btn-font h-100 w-100 py-1 px-4" (click)="scrollToDonationForm()">DONATE</button> -->
<!-- <button class="btn btn-danger btn-lg btn-font h-100 w-100 py-1 px-4"> -->
<ng-container *ngIf="mainCampaign$ | async as mainCampaign">
  <a
    class="btn btn-danger btn-lg btn-font h-100 w-100 py-1 px-4 text-white"
    [href]="mainCampaign.donateButton"
    [innerHTML]="label | translate">
  </a>
</ng-container>
<!-- </button> -->
