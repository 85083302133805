import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, HostListener} from "@angular/core";
import { TranslateAppService } from "../../../services/app/translate/translate.app.service";
import { LanguageCodeEnum, MapOfLanguageNameAndCode } from "../../../enums/language-code.enum";
import { TagNameEnum } from "../../../enums/tag-name.enum";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-translate-dropdown',
  styleUrls: ['./translate-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!--  Wrapper  -->
    <ng-container [ngSwitch]="wrapperTagName">
      <li *ngSwitchCase="listOfWrapperTagNameEnum.LI">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </li>
      <div *ngSwitchCase="listOfWrapperTagNameEnum.DIV">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </ng-container>

    <!--  Body for wrapper  -->
    <ng-template #contentTemplate>
      <li *ngIf="mobile" (window:resize)="onResize($event)" class="nav-item dropdown fw-normal mx-3">
        <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
          <i class="bi bi-translate fs-4"></i>
        </a>
        <ul
            class="dropdown-menu dropdown-menu-end bg-dark text-white border-0 rounded-0 mt-1"
            aria-labelledby="navbarDropdown">
          <ng-container *ngFor="let languageCode of languageCodeList">
            <li (click)="changeLanguage(languageCode)">
              <a
                [class.active]="languageCode === currentLanguageCode"
                [title]="mapOfLanguageNameAndCode[languageCode]"
                class="dropdown-item"
                href="javascript:void(0)">
                {{ mapOfLanguageNameAndCode[languageCode] }}
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-template>
  `
})
export class TranslateDropdownComponent implements OnInit {
  mobile = window.innerWidth >= 992;

  @Input()
  public wrapperTagName: TagNameEnum = TagNameEnum.DIV;

  public readonly listOfWrapperTagNameEnum: typeof TagNameEnum = TagNameEnum;

  public readonly mapOfLanguageNameAndCode: typeof MapOfLanguageNameAndCode = MapOfLanguageNameAndCode;

  constructor(
    private readonly translateAppService: TranslateAppService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
  }

  public ngOnInit(): void {
    this.initSubscribes();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mobile = window.innerWidth >= 992;
  }

  public get currentLanguageCode(): LanguageCodeEnum {
    return this.translateAppService.currentLanguageCode as LanguageCodeEnum;
  }

  public get languageCodeList(): LanguageCodeEnum[] {
    return this.translateAppService.languageCodeList as LanguageCodeEnum[];
  }

  /**
   *
   * @param languageCode
   */
  public changeLanguage(languageCode: LanguageCodeEnum): void {
    this.translateAppService.setLanguageCode(languageCode);

    const splitCurrentURL= this.router.url.split("/");
    const routes = ['about-us', 'faq', 'contact', 'special-projects', 'thankyou', 'donate', 'donate-car'];
    const currentRoute = splitCurrentURL[splitCurrentURL.length - 1];
    const navigationArray = languageCode === LanguageCodeEnum.en ? [currentRoute] : [languageCode, currentRoute];

    if (splitCurrentURL.length === 3) {
        this.router.navigate(navigationArray).then()
    } else if (splitCurrentURL.length === 2 && routes.includes(currentRoute)) {
      this.router.navigate([languageCode, currentRoute])
    } else {
      if (languageCode == "en") {
        this.router.navigate([""]).then()
      } else {
        this.router.navigate([languageCode]).then()
      }
    }

    this.translateAppService.changeLanguage(languageCode);
  }

  private initSubscribes(): void {
    this.initSubscribeOfChangeDefaultLanguage();
  }

  private initSubscribeOfChangeDefaultLanguage() {
    // In subscribe call back fn you can use argument - event: DefaultLangChangeEvent
    this.translateAppService.onLangChange.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }
}
